<template>
  <div class="group-teams-items">

    <div class="group-teams-items__item">
      <router-link :to="routePath" >
        <div class="group-teams-items__item__name">{{content.text}}</div>
        <BaseIcon type="chevron-right"/>
      </router-link>

      <div class="group-teams-items__item__sub-item sub-item" v-if="content.children" >
        <div class="sub-item__trigger" @click="handleClick" :class="customClass">
          <span></span>
            View subgroups
          </div>
        <div class="sub-item__body" v-show="isActive">
          <GroupTeamsSubItem  v-for="(item) in content.children" :content="item" :key="item.guid"/>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import BaseIcon from '@base/BaseIcon'
import GroupTeamsSubItem from '@blocks/groups/GroupTeamsSubItem'
export default {
  name: 'GroupTeamsItem',
  data () {
    return {
      isActive: false
    }
  },
  props: {
    content: {
      type: Object,
      required: true
    }
  },
  components: {
    BaseIcon,
    GroupTeamsSubItem
  },
  computed: {
    routePath () {
      return (this.content.context === 'documents')
        ? `/documents/list?filters=${this.content.guid}`
        : (this.content.context === 'contact')
          ? `/contacts/list?filters=${this.content.guid}`
          : `/contacts/groups-teams/details/${this.content.id}`
    },
    customClass () {
      return this.isActive ? 'is-expanded' : 'is-collapsed'
    }
  },
  methods: {
    handleClick () {
      this.isActive = !this.isActive
    }
  }
}
</script>

<style lang="scss">
.group-teams-items {
  @media print {
    background-color: transparent !important;
  }
  background-color: colors(primary);

  .sub-item {

    &__trigger {
      @media print {
        color: black;
      }
      display: flex;
      align-items: center;
      margin-top: 0.5rem;
      color: colors("background");
      font-size: 1.4rem;
      font-weight: font-weights(medium);

      span {
        @media print {
          @include add-triangle(right, black, .4em);
        }
        @include add-triangle(right, colors("background"), .4em);
        margin-right: .7rem;
        margin-left: .3rem;
      }

      &.is-expanded {
        @include media("large") {
          &:hover {
            color: shade(colors("primary"), 40%);
            cursor: pointer;
            span {
              @include add-triangle(bottom, shade(colors("primary"), 40%), .4em);
              margin-top: 0.5rem;
              margin-left: .1rem;
              margin-right: .4rem;
            }
          }
        }
        span {
          @include add-triangle(bottom, colors("background"), .4em);
          margin-top: 0.5rem;
          margin-left: .1rem;
          margin-right: .4rem;
        }
      }

      @include media("large") {
        &:hover {
          color: shade(colors("primary"), 40%);
          cursor: pointer;
          span {
            @include add-triangle(right, shade(colors("primary"), 40%), .4em);

          }
        }
      }
    }

    &__body {
      margin-left: 1.7rem;
    }

  }

  &__item {
    @media print {
      padding-bottom: 0;
    }
    padding: 2rem;
    width: 100%;

    @include media ("small-medium") {
      padding: 1.5rem;
    }

    &:nth-of-type(even) {
      border-right:none;
    }

    > a {
      @include add-transition;
      @media print {
        background-color: lightgray;
      }
      display: flex;
      justify-content: space-between;
      padding: 1rem;
      background-color: shade(colors(primary), 20%);
      color: colors("background");
      border-radius:.4rem;
      transition: .3s ease-in-out all;

      &:hover {
        background-color: colors("background");
        color: colors(primary);
      }
    }
  }

}

</style>
