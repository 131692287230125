<template>
  <div class="group-teams-subitems">
      <router-link :to="routePath" class="group-teams-subitems__item">
        <BaseIcon type="chevron-right"/>
        <div class="group-teams-subitems__name">{{content.text}}</div>
      </router-link>

  </div>
</template>

<script>
import BaseIcon from '@base/BaseIcon'
export default {
  name: 'GroupTeamsSubItem',
  props: {
    content: {
      type: Object,
      requireed: true
    }
  },
  components: {
    BaseIcon
  },
  computed: {
    routePath () {
      return `/contacts/groups-teams/details/${this.content.id}`
    }
  }

}
</script>

<style lang="scss">
  .group-teams-subitems {
    &__item {
      display: flex;
      font-size: 1.4rem;
      color: colors("background");
      margin-top: .7rem;
      .icon {
        font-size: 1.2rem;
        margin-right: .5rem;
      }
    }
  }

</style>
