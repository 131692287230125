<template>
  <div class="contacts-groups-view" v-if="loaded">
    <HubContactsGroups name="contact-group" v-if="!showDetail" />
    <div v-else>
      <transition
        name="custom-classes-transition"
        enter-active-class="animated fadeInUp delay-animation-700"
        leave-active-class="animated fadeOutDown">
        <router-view  />
      </transition>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import updateBreadcrumb from '@mixins/updateBreadcrumb'
import HubContactsGroups from '@blocks/hubs/HubContactsGroups'
export default {
  name: 'ContactsGroupsView',
  mixins: [ updateBreadcrumb ],
  components: {
    HubContactsGroups
  },
  data () {
    return {
      showDetail: false,
      loaded: false
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.switchViews(to)
    })
  },
  beforeRouteUpdate (to, from, next) {
    this.switchViews(to)
    next()
  },
  methods: {
    ...mapActions({
      fetchGetPage: 'page/fetchGetPage'
    }),
    switchViews (to) {
      this.loaded = false
      this.showDetail = !!to.params.groupId
      const service = this.showDetail ? 'contact-groups-detail' : 'contact-groups'
      const params = this.showDetail ? { groupId: to.params.groupId } : {}
      this.fetchGetPage({ service, params }).then(() => {
        this.loaded = true
      })
    }
  }
}
</script>
