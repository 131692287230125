<template>
  <ContainerHub class="hub-contacts-groups">

    <!-- Header Slot -->
    <template slot="header">
      <HubHeader
        :titleTag="'h1'"
        :title="title"
        :email="email"
        :description="description"
      />
    </template>

    <!-- Body Slot -->
    <template slot="body">
      <GroupTeams v-for="(item, index) in items" :content="item" :key="`group-${index}`" />
    </template>
  </ContainerHub>
</template>

<script>
import { mapGetters } from 'vuex'
import ContainerHub from '@containers/ContainerHub'
import HubHeader from './HubHeader'
import GroupTeams from '@blocks/groups/GroupTeams'

export default {
  name: 'HubContactsGroups',
  props: {
    name: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      title: 'page/getTitle',
      description: 'page/getDescription',
      email: 'page/getEmail',
      items: 'groups/getItems'
    })
  },
  components: {
    HubHeader,
    ContainerHub,
    GroupTeams
  }
}
</script>

<style lang="scss">
  .hub-contacts-groups{

    .group{
      margin: 0;
    }
  }
</style>
