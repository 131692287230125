<template>
  <div class="group" :class="customClass" v-if="content.title">
    <div class="group__header" @click="handleExpand" >
      <h2 class="group__header__title">{{content.title}}</h2>
      <div class="group__header__icon">
      </div>
    </div>
    <div class="group__body" v-show="isActive" v-if="content.children" :class="bodyClass">
      <GroupTeamsItem v-for="item in content.children" :content="item" :key="item.guid" />
    </div>
    <div class="custom-content" v-else v-show="isActive">
      <slot name="custom-content"></slot>
    </div>
  </div>

  <div class="group" :class="customClass" v-else>
    <div class="group__body" v-if="content.children" :class="bodyClass">
      <GroupTeamsItem v-for="item in content.children" :content="item" :key="item.guid" />
    </div>
  </div>
</template>

<script>
import GroupTeamsItem from '@blocks/groups/GroupTeamsItem'
export default {
  name: 'GroupTeams',
  data () {
    return {
      isActive: this.expand
    }
  },
  props: {
    content: {
      type: Object,
      required: true
    },
    expand: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    customClass () {
      return this.isActive ? 'is-expanded' : 'is-collapsed'
    },
    bodyClass () {
      return [
        { 'is-even': this.isEven(this.content.children.length) },
        { 'is-odd': !this.isEven(this.content.children.length) }
      ]
    }
  },
  components: {
    GroupTeamsItem
  },
  methods: {
    isEven (n) {
      return (n % 2 === 0)
    },
    handleExpand () {
      this.isActive = !this.isActive
    }
  }

}
</script>

<style lang="scss">

.group {

  // Header

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: layout("spacing") 0;
    border-bottom: 1px solid colors(foreground);

    .is-expanded & {
      border-bottom: 1px solid transparent
    }

    // Header - Title

    &__title {
      @media print {
        font-size: 16pt;
      }
      margin: 0;
      padding: 0;
      font-size: 2.4rem;
      line-height: 1.2rem;
      font-weight: font-weights(normal);
    }

    // Header - Icon

    &__icon {
      @media print {
        display: none !important;
      }
      width: 2.5rem;
      height: 2.5rem;
      position: relative;

      &::before, &::after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform:translate(-50%, -50%);
        background-color: colors(primary);
        height: 0.3rem;
        width: 2.1rem;
        border-radius: .4rem;
      }
      &::after {
        transform: translate(-50%, -50%) rotate(90deg)
      }
      .is-expanded & {
        &::after {
          display: none;
        }
      }
    }
  }

  // Body

  &__body{
    @media print {
      display: block !important;
      padding-bottom: 2rem;
      background-color: transparent;
    }
    @include media("small-medium"){
      width: calc(100% + #{layout("spacing")});
      margin-left: - layout("spacing")/2;
      margin-right: - layout("spacing")/2;
    }
    @include media("large"){
      width: calc(100% + #{layout("spacing")*2});
      margin-left: - layout("spacing");
      margin-right: - layout("spacing");
    }

    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: wrap;
    background-color: colors("primary");

    > div{
      @include media("large"){
        width: 50%;
        border-bottom: 1px solid white;

        .is-even &{
          &:nth-last-child(1),
          &:nth-last-child(2){
            border-bottom: none;
          }
        }
        .is-odd &{
          &:nth-last-child(1){
            border-bottom: none;
          }
        }
        &:nth-of-type(odd) {
          border-right: 1px solid white;
        }
      }
      @include media ("small-medium") {
        width: 100%;
        border-bottom: 1px solid white;
      }
      flex: 0 1 auto;
    }
  }
}
</style>
